import "../styles/Section7.scss";
import React from "react";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Section7() {
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [sub, setSubject] = React.useState(""); // Используем состояние для выбранного курса

  const subjects = ["Matematika", "Ingliz tili", "Kimyo", "Biologiya"];

  const botToken = "6566811418:AAFDYrV4F0J2YMipuaAZqIU3JYbtd1Aiz4g";
  const chatId = "-909675952";

  const sendMessage = (message) => {
    const base_url = `https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatId}&text=${message}&parse_mode=HTML`;

    axios.get(base_url).then((response) => {
      console.log(response);
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    // Проверяем, что все данные введены корректно
    if (!name || !phone || !sub || sub === "") {
      toast.error("Formani to'g'ri to'ldiring!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    sendMessage(
      `<b>Ism Familiya</b>: <i>${name}</i>%0A<b>Telefon raqam</b>: <i>${phone}</i>%0A<b>Fan</b>: <i>${sub}</i>`
    );

    toast.success("Ma'lumotlaringiz qabul qilindi!", {
      position: "top-right",
      autoClose: 3000,
    });

    setName("");
    setPhone("");
    setSubject(""); // Сбросить выбранный курс
  };

  return (
    <div>
      <div className="section7">
        <div className="container text-center">
          <strong className="free1">
            Bepul Darslarga Ro‘yxatdan o‘ting va 25% chegrimaga ega bo‘ling!
          </strong>
          <p className="p-2 mt-3">
            Ma'lumotlaringizni qoldiring va administratorlarimiz siz{" "}
          </p>
          <p className="p-5"> bilan bog‘lanishadi.</p>
          <form onSubmit={submitHandler}>
            <div className="f-form">
              <div className="t-input-block mb-2 me-2 wrapper">
                <input
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  name="name"
                  className="t-input wrapper w-100"
                  placeholder="Familiya va Ism"
                />
              </div>
              <div className="t-input-block mb-2 me-2 wrapper">
                <input
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                  type="tel" // Устанавливаем тип как телефон
                  name="phone"
                  className="t-input wrapper w-100"
                  placeholder="998 99 123 45 67"
                />
              </div>
              <div className="t-input-block mb-2">
                <select
                  value={sub} // Устанавливаем значение из состояния
                  onChange={(e) => setSubject(e.target.value)}
                  required
                  name="fanlar"
                  className="form-select wrapper"
                  aria-label="Default select example"
                >
                  <option value="">Kursni tanlang</option>
                  {subjects.map((o, index) => (
                    <option key={index} value={o}>
                      {o}
                    </option>
                  ))}
                </select>
              </div>
              <div className="d-input w-100">
                <button
                  style={{ marginTop: "10px" }}
                  type="submit"
                  className="btn btn-primary"
                >
                  Darslarga yozilish
                </button>
              </div>
            </div>
          </form>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
}
