import "../styles/Section1.scss";
import axios from "axios";
import React from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Section1() {
    const [name, setName] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [sub, setSubject] = React.useState("");

    const subjects = ["Matematika", "Ingliz tili", "Kimyo", "Biologiya"];

    const botToken = "6566811418:AAFDYrV4F0J2YMipuaAZqIU3JYbtd1Aiz4g";
    const chatId = "-909675952";

    const sendMessage = (message) => {
        const base_url = `https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatId}&text=${message}&parse_mode=HTML`;

        axios.get(base_url).then((response) => {
            console.log(response);
        });
    };

    const submitHandler = (e) => {
        e.preventDefault();

        if (!name || !phone || !sub || sub === "Kursni tanlang") {
            toast.error("Formani to'g'ri to'ldiring!", {
                position: "top-right",
                autoClose: 3000,
            });
            return;
        }

        sendMessage(
            `<b>Ism Familiya</b>: <i>${name}</i>%0A<b>Telefon raqam</b>: <i>${phone}</i>%0A<b>Fan</b>: <i>${sub}</i>`
        );

        toast.success("Ma'lumotlariz qabul qilindi!", {
            position: "top-right",
            autoClose: 3000,
        });

        setName("");
        setPhone("");
        setSubject("");
    };

    return (
        <div className="section1 pt-5 mb-5">
            <div className="container">
                <div className="row boxes">
                    <div className="col-md-6">
                        <h1 className="h100">
                            Grand asosida
                            <br />
                            o‘qishga kirishni
                            <br />
                            xohlaysizmi?
                        </h1>
                        <p className="p-2">91% O‘quvchilarimiz Talaba bo‘lishdi</p>
                    </div>
                    <div className="col-md-6 mt-3">
                        <img className="w-75" src="./image/hat.webp" alt="" />
                    </div>
                </div>

                <div className="container free45">
                    <div>
                        <button
                            type="button"
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                        >
                            BEPUL BOSHLASH
                        </button>
                        <div
                            className="modal fade"
                            id="exampleModal"
                            tabIndex="-1"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="exampleModalLabel">
                                            BEPUL BOSHLASH
                                        </h1>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <form onSubmit={submitHandler}>
                                        <div className="modal-body">
                                            <div className="f-form">
                                                <div className="t-input-block mb-2 me-2 w-100">
                                                    <input
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                        type="text"
                                                        autoComplete="off"
                                                        name="name"
                                                        className="t-input pt-2 pb-2 w-100 px-2"
                                                        placeholder="Familiya va Ism"
                                                    />
                                                </div>
                                                <div className="t-input-block pt-2 pb-2 w-100">
                                                    <input
                                                        value={phone}
                                                        onChange={(e) => setPhone(e.target.value)}
                                                        type="number"
                                                        autoComplete="off"
                                                        name="phone"
                                                        className="t-input w-100 pt-2 pb-2"
                                                        placeholder="+998 99 123 45 67"
                                                    />
                                                </div>
                                                <div className="t-input-block mb-2">
                                                    <select
                                                        value={sub}
                                                        onChange={(e) => setSubject(e.target.value)}
                                                        className="form-select wrapper pt-2 pb-2"
                                                        autoComplete="off"
                                                        name="fanlar"
                                                    >
                                                        <option>Kursni tanlang</option>
                                                        {subjects.map((subject, index) => (
                                                            <option key={index} value={subject}>
                                                                {subject}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                style={{ marginTop: "10px" }}
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                Darslarga yozilish
                                            </button>
                                            <ToastContainer />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tn-atom">
                        <p>1-dekabrgacha 25% chegirma</p>
                    </div>
                </div>
                </div>
                <div className='box d-flex d-block' >
                    <div className='container text-center'>
                        <strong className='free1'>Biz haqimizda raqamlarda</strong>
                        <div className="d-flex boxes">
                            <div className="gujiferri col-md-4 me-5">
                                <img className='img1' src="/image/Matem.svg" alt="not found" />
                                <img className='img2' src="/image/Ingliz.svg" alt="not found" />
                                <img className='img3' src="/image/Biolog.svg" alt="not found" />
                                <img className='img4' src="/image/Ximiya.svg" alt="not found" />
                                <div className="box1 ">
                                    Записаться на пробный урок
                                </div>
                            </div>
                            <div className="free me-5">
                                <div> <h2>1200+</h2></div>
                                <div ><p className='p-1'>O‘quvchilar</p></div>
                            </div>
                            <div className="free me-5">
                                <h2>6+</h2>
                                <p className='p-1'>yillik tajriba</p>
                            </div>
                            <div className="free">
                                <h2>90%</h2>
                                <p className='p-1'>O‘quvchilarimiz talaba bo‘lishdi</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            );
}
