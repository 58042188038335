import { Link } from "react-router-dom";
import "../styles/Section2.scss";
import React from "react";

// Компонент для отображения карточек преимуществ
function AdvantageCard({ number, title, description }) {
  return (
    <div className="col-md-4 mt-2">
      <div className="cart1 d-flex d-block">
        <div className="cart">
          <div className="circle t502__circle">{number}</div>
        </div>
        <div>
          <h5>{title}</h5>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
}

export default function Section2() {
  const advantages = [
    {
      number: 1,
      title: "Maxsus Oson o‘qitish tizimi",
      description: "Har bir mavzu maydalab oson qilib tushuntiriladi.",
    },
    {
      number: 2,
      title: "15 yildan tajribaga ega Ustozlar",
      description:
        "Bizning o‘qituvchilarimiz yangi ish boshlagan talabalar emas, balki o‘z ishining haqiqiy mutaxassislaridir.",
    },
    {
      number: 3,
      title: "Bepul kitoblar va tarqatma materiallar",
      description:
        "Ta'lim Britaniya universiteti (Oxford University Press) tomonidan ishlab chiqilgan maxsus dastur asosida olib boriladi.",
    },
    {
      number: 4,
      title: "Cheklanmagan dars soatlari",
      description: "Darsdan tashqari markazda qolib, dars qilish imkoniyati.",
    },
    {
      number: 5,
      title: "Ikkinchi ustoz xizmati",
      description: "Siz bilan 2-o‘qituvchimiz qo‘shimcha shug‘ullanadi.",
    },
    {
      number: 6,
      title: "Har oyda test sinovlari",
      description:
        "Har oyda o‘zingiz o‘qiyotgan daraja bo‘yicha DTM va Mock test sinovlari bo‘lib o‘tadi.",
    },
  ];

  return (
    <section className="section2">
      {/* Информация о центре */}
      <div className="container">
        <p className="sec2-p">
          Grand edu o'quv markazi 2019 - yilda tashkil etilgan. Hozirga kelib
          markazning filiallar soni 2ta ga yetib, Toshkent shahrining eng qulay
          joylarida o'z faoliyatini yuritib kelmoqda. Zamonaviy ilg‘or
          texnologiyalar bilan jihozlangan hamda yuqori saviyada ta’lim olish
          uchun eng yaxshi sharoitlar yaratilgan.
        </p>
      </div>

      {/* Видео-секция */}
      <div className="container">
        <div className="row d-flex justify-content-between align-items-center">
          <div className="video text-center col-xl-6 col-md-12">
            <iframe
              width="100%"
              height="350"
              src="https://www.youtube.com/embed/sWCVEu0v9bk?si=y8TKrbjDlGFJD36h"
              title="Grand edu - Видео 1"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
          <div className="video text-center col-xl-6 col-md-12">
            <iframe
              width="100%"
              height="350"
              src="https://www.youtube.com/embed/Cds-Kbq-JWU?si=M0FE5Msdyl_YXd0r"
              title="Grand edu - Видео 2"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>

      {/* Преимущества */}
      <div className="boxes23">
        <div className="container">
          <h1 className="text-center mt-2">Nima uchun aynan biz?</h1>
          <p className="text-center">Bizning o‘quv markazimizda o‘qish orqali quyidagilarga ega bo‘lasiz</p>

          <div className="row">
            {advantages.map((advantage) => (
              <AdvantageCard
                key={advantage.number}
                number={advantage.number}
                title={advantage.title}
                description={advantage.description}
              />
            ))}
          </div>

          <div className="text-center mb-5">
            <Link to="/section7">
              <button className="btn btn-primary">BEPUL DARSLARGA YOZILISH</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
