import "../styles/Section8.scss"
// import { useMemo } from "react";
// import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import React from 'react'


export default function Section8() {

    // const image = require('../assets/images/4.JPG');
    
      
    return (
        <div>
            <div className='section8' 
            // style={{ backgroundImage: `url(${image})` }}
            >
                <div className="container">
                    <div className="text-center">
                        <div className="">
                            <h1 className='h-3'>Manzil</h1>
                            <br />
                            <a className='telNumber' href="tel:+998555003434">+998 55 500 34 34</a>
                            <p className='p-8'><img className='img3' src="/image/loca2.png" alt="not found" /> Amir Temur 86 | Yunusobod Tumani</p>
                            <p className='p-8'>Minor Metrosi Kazakhstan binosi 1-qavati</p>
                            <p className='p-8'><img className='img3' src="/image/email2.png" alt="not found" /><a href="https://t.me/grandeduadmin">grandeduadmin</a></p>
                            <br />
                        </div>
                        <div className="">
                        {/* <Map /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


// function Map() {
//     const center = useMemo(() => ({ lat: 41.328814, lng: 69.283219}), []);

//     return (
//         <GoogleMap
//          zoom={15} 
//          center={center} 
//         mapContainerClassName="map-container">
//             <Marker position={center} />
//         </GoogleMap>
//     );
// }
