import "../styles/Section5.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useState } from "react";

const data = [
  {
    name: "Farrux Rasulovich",
    src: "./images2/1.JPG",
    review:
      "Kimyo. 10 yillik ish tajriba. Farmaseftika universitetiga eng yuqori ball bilan Davlat Grandi asosida o‘qishga qabul qilinganlar.",
  },
  {
    name: "Jabborov Suhrob",
    src: "./images2/2.JPG",
    review:
      "Ingliz tili (rus/uzb). 4-5 yillik tajribaga ega, IELTS 7. O‘zbekiston Jahon tillari universitetini tamomlagan.",
  },
  {
    name: "Sardorxon Urfonxonov",
    src: "./images2/3.JPG",
    review:
      "Matematika (uzb). 6-7 yillik ish tajribasiga ega. Video darsliklari Instagram, Tik Tok, YouTube kabi ijtimoiy tarmoqlarda 100 000 tadan ortiq ko‘rilgan.",
  },
  {
    name: "Doctor Xasan",
    src: "./images2/4.JPG",
    review: "Biologiya (rus) Yevro yo‘nalish bo‘yicha o‘qituvchi. 6 yillik tajriba.",
  },
  {
    name: "Uktamov Sherzod",
    src: "./images2/5.jpg",
    review: "4-5 yillik ish tajribasiga ega.",
  },
];

function Section5() {
  const [selectedTeacher, setSelectedTeacher] = useState(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2, // при ширине экрана меньше 1024 показывать 2 слайда
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1, // при ширине экрана меньше 600 показывать 1 слайд
        },
      },
    ],
  };

  return (
    <div className="section5 container">
      <div className="slider m-auto">
        <div className="smallSlider mt-3">
          <Slider {...settings}>
            {data.map((teacher, index) => (
              <div className="sliderBox" key={index}>
                <div className="slidersmallbox">
                  <img src={teacher.src} alt={teacher.name} className="sliderImg" />
                </div>
                <div>
                  <h5 className="sec5-p">{teacher.name}</h5>
                  <p className="sec5-p1">{teacher.review}</p>
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#teacherModal"
                    onClick={() => setSelectedTeacher(teacher)}
                  >
                    Batafsil
                  </button>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      {/* Модальное окно */}
      {selectedTeacher && (
        <div
          className="modal fade"
          id="teacherModal"
          tabIndex="-1"
          aria-labelledby="teacherModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="teacherModalLabel">
                  {selectedTeacher.name}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <p>{selectedTeacher.review}</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Yopish
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Section5;
