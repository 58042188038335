import "../styles/Section6.scss";
import React from "react";

export default function Section6() {
  const carouselItems = [
    { src: "./image/1.png", alt: "Classroom 1" },
    { src: "./image/2.png", alt: "Classroom 2" },
    { src: "./image/3.png", alt: "Classroom 3" },
    { src: "./image/4.png", alt: "Classroom 4" },
    { src: "./image/5.png", alt: "Classroom 5" },
    { src: "./image/6.png", alt: "Classroom 6" },
    { src: "./image/7.png", alt: "Classroom 7" },
    { src: "./image/8.png", alt: "Classroom 8" },
  ];

  return (
    <div className="section6">
      <div className="container text-center mt-5">
        {/* Заголовки */}
        <h1>Grand Edu o‘quv markazi</h1>
        <p className="p-2">Ajoyib ta'lim muhiti va zamonaviy o'quv xonalari</p>

        {/* Карусель */}
        <div
          id="carouselExampleDark"
          className="carousel carousel-light slide mb-5"
          data-bs-ride="carousel"
        >
          {/* Индикаторы */}
          <div className="carousel-indicators">
            {carouselItems.map((_, index) => (
              <button
                key={index}
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide-to={index}
                className={index === 0 ? "active" : ""}
                aria-current={index === 0 ? "true" : undefined}
                aria-label={`Slide ${index + 1}`}
              ></button>
            ))}
          </div>

          {/* Элементы карусели */}
          <div className="carousel-inner">
            {carouselItems.map((item, index) => (
              <div
                key={index}
                className={`carousel-item ${index === 0 ? "active" : ""}`}
                data-bs-interval="5000"
              >
                <img
                  src={item.src}
                  className="d-block w-100"
                  alt={item.alt}
                />
              </div>
            ))}
          </div>

          {/* Кнопки управления */}
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
}
