import { Link } from "react-router-dom";
import "../styles/Section3.scss";
import React from "react";

// Компонент для отображения карточки курса
function CourseCard({ imgSrc, title, description }) {
  return (
    <div className="col-md-4 mb-3">
      <div className="box4 text-center">
        <img className="img11 mb-3" src={imgSrc} alt={`${title} icon`} />
        <h6>{title}</h6>
        <p>{description}</p>
      </div>
    </div>
  );
}

export default function Section3() {
  const courses = [
    {
      imgSrc: "/image/math.svg",
      title: "Matematika",
      description:
        "Matematika fanidan 1 yılda tayyorlangan o‘quvchilarimizning 100% Universitetlarga o‘qishga qabul qilinishdi",
    },
    {
      imgSrc: "/image/kim.svg",
      title: "Kimyo va Biologiya",
      description:
        "Kimyo va Biologiya fanlaridan tayyorlanib nufuzli Tibbiyot Oliy o'quv yurtlari talabasiga aylaning",
    },
    {
      imgSrc: "/image/inlish.svg",
      title: "Ingliz tili (General English)",
      description:
        "Ingliz tilidan General English kurslarimizga qatnashib, IELTS olishga tayyorlaning",
    },
  ];

  return (
    <div className="section3">
      <div className="boxes20">
        <div className="container">
          {/* Заголовки */}
          <h1 className="text-center mb-3">Kurslarimiz</h1>
          <p className="text-center">
            Siz quyidagi fanlardan tayyorlanib, orzuyingizdagi
          </p>
          <p className="text-center mb-4">
            universitetga o‘qishga kirishingiz mumkin.
          </p>

          {/* Карточки курсов */}
          <div className="row">
            {courses.map((course, index) => (
              <CourseCard
                key={index}
                imgSrc={course.imgSrc}
                title={course.title}
                description={course.description}
              />
            ))}
          </div>

          {/* Кнопка */}
          <div className="text-center">
            <Link to="/section7">
              <button className="btn btn-primary">BEPUL DARSGA QATNASHISH</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
